import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import RadioButton from "../Supporting files/RadioButton/RadioButton";
import CreateNewAddress from "../components/Modals/CreateNewAddress";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Delete, Get, Post } from "../Api/api";
import { URL_CONSTANTS } from "../Api/ApiUrl";
import { formatCurrency } from "../Supporting files/HelpingFunction";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateAddress, updateCart } from "../Redux/Slice/UserSlice";
import { userRoutes } from "../Routes/UserRoutes";
import { RenderRazorpay } from "../components/Payment";
import Loader from "../components/Loader/Loader";
import LocalShippingIcon from "@mui/icons-material/LocalShipping"; // Import the icon

const Checkout = () => {
  const UserInfo = useSelector((state) => state?.user?.userInfo?.userDetail);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedAddress, setSelectedAddress] = useState(
    UserInfo?.deliveryAddress[0]
  );
  const [newAddr, setNewAddr] = useState(false);

  useEffect(() => {
    if (!newAddr) {
      setSelectedAddress(UserInfo.deliveryAddress[0]);
    }
  }, [newAddr]);

  const [selectPaymentMode, setSelectPaymentMode] = useState(
    "Credit / Debit Card"
  );
  const [cartItems, setcartItems] = useState([]);
  const [totalMrp, settotalMrp] = useState(0);
  const [totalPrice, settotalPrice] = useState(0);
  const [pageStatus, setpageStatus] = useState(0);
  const [paymentGatway, setpaymentGatway] = useState({
    r_OrderId: "",
    amount: "",
    open: false,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchCart();
  }, []);

  useEffect(() => {
    findTotalMrpOrPrice();
  }, [cartItems]);

  const checkoutOrder = async () => {
    let address = { ...selectedAddress };
    delete address._id;
    let payload = {
      paymentMode: "credit_card",
      deliveryAddress: address,
    };
    try {
      const response = await Post(
        `${URL_CONSTANTS.createOrder}`,
        JSON.stringify(payload)
      );
      let { amount, r_OrderId } = response.response;
      setLoading(false);
      RenderRazorpay(r_OrderId, amount);
      dispatch(updateCart([]));
    } catch (error) {
      console.error("Error ", error.message);
    }
  };

  const removeAddress = async (id) => {
    try {
      const response = await Delete(`${URL_CONSTANTS.removeAddress}${id}`);
      dispatch(updateAddress(response.response));
      toast.success("Your address removed successfully");
      if (id === selectedAddress._id) {
        setSelectedAddress({});
      }
    } catch (error) {
      console.error("Error ", error.message);
    }
  };

  const handleAddressChange = (event) => {
    const addressId = event.target.value;
    const address = UserInfo.deliveryAddress.find(
      (addr) => addr._id === addressId
    );
    setSelectedAddress(address);
  };
  const handlePaymentModeChange = (event) => {
    const paymentMode = event.target.value;
    setSelectPaymentMode(paymentMode);
  };
  const fetchCart = async () => {
    try {
      const response = await Get(`${URL_CONSTANTS.getCartItems}`);
      setcartItems(response.response.products);
      if (response.response.products.length === 0) {
        navigate(userRoutes.cart);
      }
    } catch (error) {
      navigate(userRoutes.cart);
      setcartItems([]);
      console.error("Error ", error.message);
    }
  };

  const findTotalMrpOrPrice = () => {
    let mrp = 0;
    let price = 0;
    cartItems.forEach((item) => {
      const amountMrp = +item.productDetail.variants.sizes.mrp;
      mrp += amountMrp * item.quantity;

      const amountPrice = +item.productDetail.variants.sizes.price;
      price += amountPrice * item.quantity;
    });
    settotalMrp(mrp);
    settotalPrice(price);
  };
  const today = new Date();
  today.setDate(today.getDate() + 6);

  // Array of month names
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Manually format the date as DD Month YYYY
  const day = String(today.getDate()).padStart(2, "0"); // Add leading zero if necessary
  const month = monthNames[today.getMonth()]; // Get month name from the array
  const year = today.getFullYear();

  const deliveryDate = `${day} ${month} ${year}`;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pageStatus]);
  
  return (
    <>
      {loading && <Loader />}

      <CreateNewAddress open={newAddr} setOpen={setNewAddr} />
      <Box className="mainWrapper">
        {/* <Header /> */}
        <Box
          className={`checkoutSection ${
            pageStatus === 1 ? "paymentSection" : ""
          }`}
        >
          <Grid
            container
            padding={0}
            columnSpacing={3}
            style={{ position: "relative" }}
          >
            {pageStatus === 0 ? (
              <Grid xs={12} sm={12} md={6} className="userAddr">
                <h4 className="mainhead mb-3">Select Delivery Address</h4>
                {UserInfo?.deliveryAddress?.length > 0 &&
                  UserInfo.deliveryAddress.map((address) => (
                    <div className="address mb-6" key={address._id}>
                      <div className="userName mb-4">
                        <RadioButton
                          value={selectedAddress ? selectedAddress._id : ""}
                          inp_value={address._id}
                          value_update={handleAddressChange}
                        />
                        <p className="mainhead">{address.name}</p>
                        <div className="ctaBtn">
                          <Button className="borderBtn xsm">
                            {address.tag}
                          </Button>
                        </div>
                      </div>
                      <p>{address.address}</p>
                      <p>
                        {address.city} {address.district} {address.pincode}
                      </p>
                      <p className="my-3">
                        Mobile- <span>{address.phone}</span>
                      </p>
                      <div className="ctaBtn p-0">
                        <Button
                          className="submitBtn xsm mr-3"
                          onClick={() => {
                            removeAddress(address._id);
                          }}
                        >
                          Remove
                        </Button>
                      </div>
                    </div>
                  ))}

                <div className="ctaBtn p-0 mb-5">
                  <Button className="dotted" onClick={() => setNewAddr(true)}>
                    Add New Address
                  </Button>
                </div>
              </Grid>
            ) : (
              <Grid xs={12} sm={12} md={6} className="payment_methods">
                <div className="payyy">
                  <h4 className="mainhead mb-9">We Accept Payment Methods</h4>
                  <div className="allmethods">
                    {/* <div className="method_options">
                      <RadioButton
                        value={"cod"}
                        inp_value={"cod"}
                        value_update={handlePaymentModeChange}
                      />
                      <div>
                        <p className="radio_label mb-2">{"Pay on Delivery"}</p>
                        <p className="radio_sublabel">
                          {"Pay with cash on delivery"}
                        </p>
                      </div>
                    </div> */}
                    <div className="method_options credit_cards">
                      <RadioButton
                        inp_value={"Credit / Debit Card"}
                        value={selectPaymentMode}
                        value_update={handlePaymentModeChange}
                      />
                      <div className="visabox w-100">
                        <div>
                          <p className="radio_label mb-2">
                            {"Credit/Debit Cards"}
                          </p>
                          <p className="radio_sublabel">
                            {"Pay with your Credit/Debit Card"}
                          </p>
                        </div>
                        <div>
                          <img
                            loading="eager"
                            className="pl-1"
                            src="images/visa1.svg"
                            alt="visa"
                          />
                          <img
                            loading="eager"
                            className="pl-1"
                            src="images/visa2.svg"
                            alt="visa"
                          />
                          <img
                            loading="eager"
                            className="pl-1"
                            src="images/visa3.svg"
                            alt="visa"
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="card_input ml-10">
                      <Grid container padding={0} columnSpacing={2}>
                        <Grid xs={12} className="inputs mb-3">
                          <input
                            className="card_no"
                            type="number"
                            value={card}
                            onChange={(e) => setCard(e.target.value)}
                            placeholder="Card Number"
                          />
                          <img loading="eager" src="images/card.svg" />
                        </Grid>
                        <Grid xs={6} className="inputs">
                          <input
                            className="card_no"
                            type="number"
                            value={card}
                            onChange={(e) => setCard(e.target.value)}
                            placeholder="MM / YY"
                          />
                          <img loading="eager" src="images/date.svg" />
                        </Grid>
                        <Grid xs={6} className="inputs">
                          <input
                            className="card_no"
                            type="password"
                            value={card}
                            onChange={(e) => setCard(e.target.value)}
                            placeholder="CVV"
                          />
                          <img loading="eager" src="images/lock.svg" />
                        </Grid>
                      </Grid>
                    </div> */}

                    <div className="method_options">
                      <RadioButton
                        inp_value={"bank"}
                        value={selectPaymentMode}
                        value_update={handlePaymentModeChange}
                      />
                      <div>
                        <p className="radio_label mb-2">{"Net Banking"}</p>
                        <p className="radio_sublabel">
                          {"Make payment directly through bank account"}
                        </p>
                      </div>
                    </div>
                    <div className="method_options">
                      <RadioButton
                        inp_value={"wallet"}
                        value={selectPaymentMode}
                        value_update={handlePaymentModeChange}
                      />
                      <div>
                        <p className="radio_label mb-2">{"Wallets"}</p>
                        <p className="radio_sublabel">
                          {"PhonePe, Amazon Pay, Paytm"}
                        </p>
                      </div>
                    </div>
                    <div className="method_options">
                      <RadioButton
                        inp_value={"upi"}
                        value={selectPaymentMode}
                        value_update={handlePaymentModeChange}
                      />
                      <div>
                        <p className="radio_label mb-2">{"Google Pay/ UPI"}</p>
                        <p className="radio_sublabel">
                          {"Make payment through Gpay, PhonePe, Paytm etc"}
                        </p>
                        <div className="otherOptions mt-3 mb-7">
                          <img loading="eager" src="images/phonepe.svg" />
                          <img loading="eager" src="images/googlepay.svg" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            )}

            <Grid xs={12} sm={12} md={6}>
              <div className="pricing">
                <h4 className="mainhead mb-3">
                  Price Details ({`${cartItems?.length}`} item)
                </h4>
                <div className="rates mb-3">
                  <p>Total MRP</p>
                  <p>{formatCurrency(totalMrp)}</p>
                </div>
                <div className="rates mb-3">
                  <p>Discount on MRP</p>
                  <p className="discount">
                    {formatCurrency(+totalMrp - +totalPrice)}
                  </p>
                </div>
                <div className="rates mb-3">
                  <p>Subtotal</p>
                  <p className="discount">{formatCurrency(totalPrice)}</p>
                </div>

                <div className="rates">
                  <p>
                    Shipping{" "}
                    {/* <h6 className="little">
                      Inclusive all taxes
                    </h6> */}
                  </p>
                  <p className="delivery">Free</p>
                </div>
              </div>
              <div className="rates my-6">
                <h2 className="txtLg">To Pay</h2>
                <h2 className="txtLg">{formatCurrency(totalPrice)}</h2>
              </div>
              <div
                style={{
                  border: "1px solid #ddd",
                  borderRadius: "8px",
                  padding: "16px 0px",
                  boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
                  fontSize: "14px",
                  width: "100%",
                  textAlign: "center",
                  marginBottom: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#8E282B",
                    fontSize: 16,
                  }}
                >
                  <LocalShippingIcon
                    style={{ marginRight: "8px", color: "#8E282B" }}
                  />{" "}
                  {/* Icon with spacing */}
                  <span color="#8E282B">
                    Earliest delivery by {deliveryDate}
                  </span>
                </div>
              </div>
              <div className="ctaBtn text-center mb-6">
                <Button
                  className="submitBtn w-100"
                  onClick={() => {
                    if (
                      cartItems.some(
                        (item) => item.productDetail.variants.sizes.quantity < 1
                      )
                    ) {
                      return toast.error(
                        "Some of the products is out of stock"
                      );
                    }
                    if (pageStatus === 0) {
                      if (!selectedAddress) {
                        return toast.error("Please select an address");
                      }
                      setpageStatus(1);
                    } else {
                      setLoading(true);
                      checkoutOrder();
                    }
                  }}
                >
                  {pageStatus === 0
                    ? "Continue"
                    : `Pay ${formatCurrency(totalPrice)}`}
                </Button>
                {pageStatus === 1 && (
                  <div className="ctaBtn mt-2">
                    <Button
                      className="backbtn w-100"
                      onClick={() => {
                        setpageStatus(0);
                      }}
                    >
                      Back
                    </Button>
                  </div>
                )}
              </div>
              <h2 className="txtLg text-right mb-3">
                Need Help?{" "}
                <span
                  onClick={() => navigate(userRoutes.contactUs)}
                  style={{ cursor: "pointer" }}
                >
                  Contact Us
                </span>
              </h2>
            </Grid>
          </Grid>
        </Box>
        <Footer />
      </Box>
    </>
  );
};

export default Checkout;
