import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "../commonSCSS/common.scss";
import Footer from "../components/Footer";
import Header from "../components/Header";

const PrivacyPolicy = () => {
  return (
    <>
      <Box className="mainWrapper">
        {/* <Header /> */}
        <Box className="our_blogs privacy">
          <Box>
            <h1>Privacy Policy</h1>
            <h2>
              Home/<span> Privacy Policy</span>
            </h2>
          </Box>
          <Box className="privacySection">
            <Grid container padding={0}>
              <Grid xs={12} className="mb-6">
                <h3 className="para mb-3">
                  This Privacy Policy explains how Ishnoor.in collects, uses,
                  and shares your personal information when you visit our
                  website or make a purchase.
                </h3>
                <h3 className="para_heading mb-3">Information We Collect</h3>
                <h3 className="para mb-3">
                  At Ishnoor.in, we collect and store the information you
                  provide to us. To create an account or subscribe to our
                  services, we require basic personal details. Additionally, our
                  systems automatically gather information about your device,
                  such as your IP address. Please note that your IP address does
                  not personally identify you.
                </h3>
              </Grid>
              <Grid xs={12} className="mb-6">
                <h3 className="para_heading mb-3">
                  How We Use Your Information
                </h3>
                <h3 className="para mb-3">
                  Ishnoor.in retains all information collected through our
                  website. We may use this information to:
                </h3>
                <h3 className="point mb-3 pl-15">
                  Contact you regarding your orders or inquiries
                </h3>
                <h3 className="point mb-3 pl-15">
                  Monitor and enhance our website's performance
                </h3>
                <h3 className="point mb-3 pl-15">
                  Analyze visitor demographics and locations
                </h3>
                <h3 className="para mb-3">
                  When you place an order, some of your personal information may
                  be shared with trusted third parties, such as courier services
                  and payment processors. We ensure that your data is always
                  handled securely.
                </h3>
                <h3 className="para mb-3">
                  By using our website, you consent to the terms outlined in
                  this Privacy Policy. Ishnoor reserves the right to modify this
                  policy at any time at our discretion.
                </h3>
              </Grid>
              <Grid xs={12} className="mb-6">
                <h3 className="para_heading mb-3">Data Retention</h3>
                <h3 className="para mb-3">
                  We retain your order information for our records unless you
                  request its deletion.
                </h3>
              </Grid>
              <Grid xs={12} className="mb-6">
                <h3 className="para_heading mb-3">Changes to This Policy</h3>
                <h3 className="para mb-3">
                  We may update this Privacy Policy from time to time to reflect
                  changes in our practices or for other operational, legal, or
                  regulatory reasons.
                </h3>
              </Grid>
              <Grid xs={12} className="mb-6">
                <h3 className="para_heading mb-3">Complaints & Grievance</h3>
                <h3 className="para mb-3">
                  For any complaints, services related issues or policy, claim
                  related queries/ customer can reach us on
                </h3>
              </Grid>
              <Grid xs={12} className="df mb-6" gap={"12px"}>
                <Box className="con_box df" gap={"12px"}>
                  <Box className="imgdiv">
                    <img
                      loading="eager"
                      src="images/contact_mail.svg"
                      alt="mail"
                    />
                  </Box>
                  <Box>
                    <h2 className="para_heading mb-1">Email</h2>
                    <h2 className="para">support@ishnoor.in</h2>
                  </Box>
                </Box>
                <Box className="con_box df" gap={"12px"}>
                  <Box className="imgdiv">
                    <img
                      loading="eager"
                      src="images/contact_num.svg"
                      alt="num"
                    />
                  </Box>
                  <Box>
                    <h2 className="para_heading mb-1"> Contact Us</h2>
                    <h2 className="para">+91 8175918027</h2>
                  </Box>
                </Box>
              </Grid>
              <Grid xs={12} className="mb-6">
                <h3 className="para">
                  For more details : Read our Grievance Policy
                </h3>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Footer />
      </Box>
    </>
  );
};

export default PrivacyPolicy;
